@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}


/* @media (max-width: 900px) {
  .makeStyles-mainContainer-36
  {
   flex-direction: column;
   background-color: #212326;
  }
  .jss39 {
    display: flex;
    align-items: center;
    flex-direction: column;
}
}
@media (max-width: 900px) {
  .makeStyles-mainContainer-60 
  {
   flex-direction: column;
   background-color: #212326;
  }
  .jss39 {
    display: flex;
    align-items: center;
    flex-direction: column;
}
}

@media (max-width: 900px) {
  .makeStyles-root-36
  {
   flex-direction: column;
   background-color: #212326;
  }
  .jss115 {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 30px;
    background: #F8F9FA;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

}

@media (max-width: 900px) {
  .makeStyles-root-83
  {
   flex-direction: column;
   background-color: #212326;
  }
} */


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* custom scrollbar */
/* ::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
} */

/* Start chat Custom CSS */

.str-chat {
  height: 90vh !important;
}

.str-chat.str-chat-channel.messaging {
  background: #fafafa;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.06), 0px 2px 30px rgba(0, 0, 0, 0.1);
}

.dark.str-chat.str-chat-channel.messaging {
  background: #212326;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
}

.str-chat.str-chat-channel-list.messaging {
  background: #fafafa;
}

.dark.str-chat.str-chat-channel-list.messaging {
  background: #212326 !important;
}

.str-chat-channel .str-chat__container {
  padding-top: 0;
}

.str-chat-channel.messaging .str-chat__main-panel {
  padding: 0 !important;
  min-width: 250px;
  padding: 0px;
}

.messaging.str-chat .str-chat__list .str-chat__reverse-infinite-scroll {
  padding-top: 0;
}

.messaging.str-chat.dark .str-chat__list {
  padding: 0 30px 0;
  background: #282a2d;
}

.str-chat-channel.messaging .str-chat__main-panel {
  padding: 30px 30px 0 0px;
}

.str-chat-channel.messaging .str-chat__main-panel:not(:last-child) {
  padding: 20px 0 0 0px;
}

.str-chat-channel.messaging .str-chat__main-panel:not(:last-child) .messaging__channel-header {
  border-radius: 10px 0px 0px 0px;
}

.str-chat__message-simple-status {
  display: none;
}

.messaging.str-chat.dark .str-chat__message--system__date {
  color: rgba(255, 255, 255, 0.7);
}

.messaging.str-chat.dark .str-chat__message--system__text p {
  color: rgba(255, 255, 255, 0.7);
}

.messaging.str-chat.dark .str-chat__message--system__line {
  background: rgba(255, 255, 255, 0.1);
}

.str-chat__message--system {
  padding: 20px;
}

.top-margin-mobilepage{
  margin-top: 20px;
}
.messaging__channel-header {
    background: rgba(255, 255, 255, 0.9);
    -webkit-transform: matrix(1, 0, 0, 1, 0, 0);
            transform: matrix(1, 0, 0, 1, 0, 0);
    box-shadow: 0px 7px 9px rgba(0, 0, 0, 0.03), 0px 1px 0px rgba(0, 0, 0, 0.03);
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 60px;
    padding-left: 20px;
  }
  
  .messaging__channel-header .channel-header__name {
    flex: 1 1;
    font-family: sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 20px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.9);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .str-chat.dark .messaging__channel-header {
    background: rgba(46, 48, 51, 0.98);
    box-shadow: 0px 7px 9px rgba(0, 0, 0, 0.03), 0px 1px 0px rgba(0, 0, 0, 0.03);
  }
  
  .str-chat.dark .messaging__channel-header .channel-header__name {
    color: rgba(255, 255, 255, 0.9);
  }
  .avatar-group {
    width: "5%";
    height: 10;
    justify-content: "center";
    align-items: "center";
  }
   .messaging__channel-header__right {
    display: flex;
    align-items: center;
    margin-right: 16px;
  }
  
  .messaging__channel-header__avatars {
    display: flex;
    align-items: center;
    height: 40px;
    min-width: 40px;
    max-width: 40px;
    border-radius: 20px;
    overflow: hidden;
    margin-right: 20px;
    margin-left: 20px;
  }
  
