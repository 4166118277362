.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


/* @media (max-width: 900px) {
  .makeStyles-mainContainer-36
  {
   flex-direction: column;
   background-color: #212326;
  }
  .jss39 {
    display: flex;
    align-items: center;
    flex-direction: column;
}
}
@media (max-width: 900px) {
  .makeStyles-mainContainer-60 
  {
   flex-direction: column;
   background-color: #212326;
  }
  .jss39 {
    display: flex;
    align-items: center;
    flex-direction: column;
}
}

@media (max-width: 900px) {
  .makeStyles-root-36
  {
   flex-direction: column;
   background-color: #212326;
  }
  .jss115 {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 30px;
    background: #F8F9FA;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

}

@media (max-width: 900px) {
  .makeStyles-root-83
  {
   flex-direction: column;
   background-color: #212326;
  }
} */


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* custom scrollbar */
/* ::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
} */

/* Start chat Custom CSS */

.str-chat {
  height: 90vh !important;
}

.str-chat.str-chat-channel.messaging {
  background: #fafafa;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.06), 0px 2px 30px rgba(0, 0, 0, 0.1);
}

.dark.str-chat.str-chat-channel.messaging {
  background: #212326;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
}

.str-chat.str-chat-channel-list.messaging {
  background: #fafafa;
}

.dark.str-chat.str-chat-channel-list.messaging {
  background: #212326 !important;
}

.str-chat-channel .str-chat__container {
  padding-top: 0;
}

.str-chat-channel.messaging .str-chat__main-panel {
  padding: 0 !important;
  min-width: 250px;
  padding: 0px;
}

.messaging.str-chat .str-chat__list .str-chat__reverse-infinite-scroll {
  padding-top: 0;
}

.messaging.str-chat.dark .str-chat__list {
  padding: 0 30px 0;
  background: #282a2d;
}

.str-chat-channel.messaging .str-chat__main-panel {
  padding: 30px 30px 0 0px;
}

.str-chat-channel.messaging .str-chat__main-panel:not(:last-child) {
  padding: 20px 0 0 0px;
}

.str-chat-channel.messaging .str-chat__main-panel:not(:last-child) .messaging__channel-header {
  border-radius: 10px 0px 0px 0px;
}

.str-chat__message-simple-status {
  display: none;
}

.messaging.str-chat.dark .str-chat__message--system__date {
  color: rgba(255, 255, 255, 0.7);
}

.messaging.str-chat.dark .str-chat__message--system__text p {
  color: rgba(255, 255, 255, 0.7);
}

.messaging.str-chat.dark .str-chat__message--system__line {
  background: rgba(255, 255, 255, 0.1);
}

.str-chat__message--system {
  padding: 20px;
}

.top-margin-mobilepage{
  margin-top: 20px;
}