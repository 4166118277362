.messaging__channel-header {
    background: rgba(255, 255, 255, 0.9);
    transform: matrix(1, 0, 0, 1, 0, 0);
    box-shadow: 0px 7px 9px rgba(0, 0, 0, 0.03), 0px 1px 0px rgba(0, 0, 0, 0.03);
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 60px;
    padding-left: 20px;
  }
  
  .messaging__channel-header .channel-header__name {
    flex: 1;
    font-family: sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 20px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.9);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .str-chat.dark .messaging__channel-header {
    background: rgba(46, 48, 51, 0.98);
    box-shadow: 0px 7px 9px rgba(0, 0, 0, 0.03), 0px 1px 0px rgba(0, 0, 0, 0.03);
  }
  
  .str-chat.dark .messaging__channel-header .channel-header__name {
    color: rgba(255, 255, 255, 0.9);
  }
  .avatar-group {
    width: "5%";
    height: 10;
    justify-content: "center";
    align-items: "center";
  }
   .messaging__channel-header__right {
    display: flex;
    align-items: center;
    margin-right: 16px;
  }
  
  .messaging__channel-header__avatars {
    display: flex;
    align-items: center;
    height: 40px;
    min-width: 40px;
    max-width: 40px;
    border-radius: 20px;
    overflow: hidden;
    margin-right: 20px;
    margin-left: 20px;
  }
  